<template>
  <div class="card">
  <div class="card-header">
    <h4>Sitemap</h4>
  </div>
    <div class="card-body">
  <div class="super-view">
    <div class="header-menu">
      <ul class="custom-list">
        <li class="custom-list-item">
          <router-link :to="{ name: 'Home' }" class="custom-link">Home</router-link>
        </li>
        <li class="custom-list-item">
          <router-link :to="{ name: 'PreOrder' }" class="custom-link">Pre Order</router-link>
        </li>
        <slot v-for="categoryInfo in $store.state.headerMenus" >
          <li class="custom-list-item">
            <router-link
              :to="{ name: 'Category', params: {  categoryUrl: categoryInfo.targetUrl } }"
              class="custom-link">{{ categoryInfo.title }}</router-link>
          </li>
          <slot v-if="categoryInfo.sub_menu.length > 0">
            <ul class="custom-sublist">
              <slot v-for="subMenu in categoryInfo.sub_menu" >
                <li class="custom-sublist-item">
                  <router-link
                    :to="{ name: 'Category', params: {  categoryUrl:subMenu.targetUrl } }"
                    class="custom-link">{{ subMenu.title }}</router-link>
                </li>
                <slot v-if="subMenu.sub_sub_menu.length > 0">
                  <ul class="custom-sub-sublist">
                    <slot v-for="normalMenu in subMenu.sub_sub_menu" >
                      <li class="custom-sub-sublist-item">
                        <router-link
                          :to="{ name: 'Category', params: {  categoryUrl: normalMenu.targetUrl } }"
                          class="custom-link">{{ normalMenu.title }}</router-link>
                      </li>
                    </slot>
                  </ul>
                </slot>
              </slot>
            </ul>
          </slot>
        </slot>
      </ul>
    </div>

    <div class="footer-menu">
      <ul class="custom-list">
        <slot v-for="(megaMenu, megaIndex) in $store.state.footerMenus" >
          <li class="custom-list-item">{{ megaMenu.title }}</li>
          <ul class="custom-sublist">
            <slot v-for="(subMenu, subIndex) in megaMenu.sub_menu" >
              <li class="custom-sublist-item">
                <router-link
                  :to="{ name: 'Content', params: { contentUrl: subMenu.title.split(' ').join('-'), contentId: subMenu.id } }"
                  class="custom-link">{{ subMenu.title }}</router-link>
              </li>
            </slot>
          </ul>
        </slot>
      </ul>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
export default {
};
</script>

<style>
.super-view {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.header-menu,
.footer-menu {
  flex: 1;
  margin: 0 20px;
}

a {
  color: black;
}

/* Additional custom styles can be added as needed */
</style>
